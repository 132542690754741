.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity))
}

.bg-inset {
  --bg-opacity: 1;
  background-color: #dee2e7;
  background-color: rgba(222, 226, 231, var(--bg-opacity))
}

.bg-gray-200 {
  --bg-opacity: 1;
  background-color: #e9ecef;
  background-color: rgba(233, 236, 239, var(--bg-opacity))
}

.bg-red-100 {
  --bg-opacity: 1;
  background-color: #ee4135;
  background-color: rgba(238, 65, 53, var(--bg-opacity))
}

.bg-orange {
  --bg-opacity: 1;
  background-color: #f0ab43;
  background-color: rgba(240, 171, 67, var(--bg-opacity))
}

.bg-green-100 {
  --bg-opacity: 1;
  background-color: #2dd67e;
  background-color: rgba(45, 214, 126, var(--bg-opacity))
}

.border-gray-400 {
  --border-opacity: 1;
  border-color: #ced4da;
  border-color: rgba(206, 212, 218, var(--border-opacity))
}

.border-red-200 {
  --border-opacity: 1;
  border-color: #d53d2d;
  border-color: rgba(213, 61, 45, var(--border-opacity))
}

.border-orange {
  --border-opacity: 1;
  border-color: #f0ab43;
  border-color: rgba(240, 171, 67, var(--border-opacity))
}

.border-green-200 {
  --border-opacity: 1;
  border-color: #469c23;
  border-color: rgba(70, 156, 35, var(--border-opacity))
}

.rounded-md {
  border-radius: 0.375rem
}

.border-solid {
  border-style: solid
}

.border-2 {
  border-width: 2px
}

.border {
  border-width: 1px
}

.border-r-0 {
  border-right-width: 0
}

.border-b-0 {
  border-bottom-width: 0
}

.border-l-0 {
  border-left-width: 0
}

.border-t-4 {
  border-top-width: 4px
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline {
  display: inline
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.flex-col {
  flex-direction: column
}

.flex-wrap {
  flex-wrap: wrap
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.justify-center {
  justify-content: center
}

.justify-between {
  justify-content: space-between
}

.font-light {
  font-weight: 300
}

.font-normal {
  font-weight: 400
}

.font-medium {
  font-weight: 500
}

.font-bold {
  font-weight: 700
}

.h-12 {
  height: 3rem
}

.h-32 {
  height: 8rem
}

.h-40 {
  height: 10rem
}

.h-64 {
  height: 16rem
}

.h-full {
  height: 100%
}

.text-xs {
  font-size: 0.75rem
}

.text-base {
  font-size: 1rem
}

.text-lg {
  font-size: 1.125rem
}

.text-xl {
  font-size: 1.25rem
}

.text-2xl {
  font-size: 1.5rem
}

.m-1 {
  margin: 0.25rem
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.mr-2 {
  margin-right: 0.5rem
}

.mb-2 {
  margin-bottom: 0.5rem
}

.ml-2 {
  margin-left: 0.5rem
}

.mb-4 {
  margin-bottom: 1rem
}

.mt-12 {
  margin-top: 3rem
}

.p-1 {
  padding: 0.25rem
}

.p-2 {
  padding: 0.5rem
}

.p-3 {
  padding: 0.75rem
}

.p-4 {
  padding: 1rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.pr-2 {
  padding-right: 0.5rem
}

.pl-2 {
  padding-left: 0.5rem
}

.pr-3 {
  padding-right: 0.75rem
}

.absolute {
  position: absolute
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.left-0 {
  left: 0
}

.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)
}

.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
}

.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)
}

.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)
}

.text-center {
  text-align: center
}

.text-black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity))
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.text-gray-600 {
  --text-opacity: 1;
  color: #6c757d;
  color: rgba(108, 117, 125, var(--text-opacity))
}

.text-gray-700 {
  --text-opacity: 1;
  color: #495057;
  color: rgba(73, 80, 87, var(--text-opacity))
}

.text-gray-800 {
  --text-opacity: 1;
  color: #343a40;
  color: rgba(52, 58, 64, var(--text-opacity))
}

.lowercase {
  text-transform: lowercase
}

.capitalize {
  text-transform: capitalize
}

.visible {
  visibility: visible
}

.whitespace-no-wrap {
  white-space: nowrap
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-12 {
  width: 3rem
}

.w-1\/3 {
  width: 33.333333%
}

.w-3\/4 {
  width: 75%
}

.w-full {
  width: 100%
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform
}

.transition-shadow {
  transition-property: box-shadow
}

.duration-150 {
  transition-duration: 150ms
}

.duration-200 {
  transition-duration: 200ms
}

@-webkit-keyframes spin {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(360deg)
  }
}

@-webkit-keyframes ping {
  0% {
    transform: scale(1);
    opacity: 1
  }

  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 1
  }

  75%, 100% {
    transform: scale(2);
    opacity: 0
  }
}

@-webkit-keyframes pulse {
  0%, 100% {
    opacity: 1
  }

  50% {
    opacity: .5
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1
  }

  50% {
    opacity: .5
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: translateY(0);
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    transform: translateY(0);
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@media (min-width: 768px) {
  .md\:w-1\/2 {
    width: 50%
  }
}